<template>
   <div class="contain">
      <div class="title">版权声明</div>
      <div class="prag">
         <div class="items">
            <div class="mb-7">1</div>
            <div class="mb-12">
               Uptick中所展示、传播的任何图片、图形、字体、文本等设计素材均由应用使用者创作上传，请用户确保享有所上传内容的著作权，或已相关授权。未经其他原始创作者许可，用户不得将他人作品进行修改、改编、汇编，不得依据Uptick中展示的作品进行二次创作、发行。因用户违约使用，或超出相应授权范围所产生的一切不利后果均由用户自行承担，Uptick不因此承担任何责任。
            </div>
         </div>
         <div class="items">
            <div class="mb-7">2</div>
            <div class="mb-12">
               Uptick不享有用户上传相关素材的著作权、专利权、商标权、商业秘密等知识产权。且不承担任何由用户所上传内容、版权纠纷产生的经济损失与相关责任。
            </div>
         </div>
         <div class="items">
            <div class="mb-7">3</div>
            <div class="mb-12">
               Uptick将定期巡检用户所创建上传的内容，有权处理涉及侵权、虚假、色情、暴力、不道德、过度广告等违规内容。Uptick有权在不事先通知相应发布用户的情况下自行删除相关内容。处理后，被处理内容将无法在应用内查看，但会在个人账号里保留。
            </div>
         </div>
         <div class="items">
            <div class="mb-7">4</div>
            <div class="mb-12">
               Uptick鼓励用户对应用内的作品进行监督，Uptick已经明确告知用户不得侵犯他人合法权益，并已设置专业人员就Uptick中所展示的作品进行定期/不定期审查，但因Uptick中用户上传作品庞大，无法一一在第一时间排除所有存在权属瑕疵作品。如您发现Uptick中存在侵犯您或任意第三方知识产权的作品，您可以在应用内进行投诉，或者联系service@uptickproject.com，我们将在收到您的反馈后，在合理期限内予以核实，并处理。
            </div>
            <div class="mb-12">
               您所提交的反馈中应包含如下材料：
            </div>
            <div class="mb-12">
               （1）Uptick账号信息，包括个人信息与联系方式；<br />
               （2）您所需投诉的相关作品链接、名称及相关信息；<br />
               （3）如果您是原创者，提供可初步证明您为权利人的相关权属材料（包括但不限于首次发表记录、版权登记证书、作品源文件等）。如果是第三方，请说明情况。
            </div>
         </div>
         <div class="items">
            <div class="mb-7">5</div>
            <div class="mb-12">
               未经Uptick许可，任何人不得通过爬虫程序、脚本、机器人程序等窃取Uptick所展示、传播的作品，不得故意绕开Uptick所设置的网络障碍侵犯Uptick合法权益；不得复制、仿造Uptick网站实施侵权行为。不得就Uptick的源代码、网站技术措施进行反汇编、反向编译、反向工程等。
            </div>
         </div>
         <div class="items">
            <div class="mb-7">6</div>
            <div class="mb-12">
               请您确保您已充分理解并同意本文档中相关表述，如您不接受本声明中部分或全部条款的，请您立即停止使用Uptick服务。您使用Uptick服务的行为视为您已知悉并同意遵守Uptick相关条款。
            </div>
         </div>
      </div>
   </div>
</template>

<script>
  export default {
    name: "term_zh",
    mounted() {
       window.eventBus.$on('LangChange', this.onLangChange);
    },
    beforeDestroy() {
      window.eventBus.$off("LangChange", this.onLangChange);
    },
    methods: {
       onLangChange() {
         if(this.$vuetify.lang.current == 'en'){
            this.$router.push({name:'Term'})
         }else if(this.$vuetify.lang.current == 'zh'){
            this.$router.push({name:'Termzh'})
         }else if(this.$vuetify.lang.current == 'ja'){
            this.$router.push({name:'Term'})
         }else if(this.$vuetify.lang.current == 'ko'){
            this.$router.push({name:'Term'})
         }else if(this.$vuetify.lang.current == 'pt'){
            this.$router.push({name:'Term'})
         }
       }
    }
  };
</script>

<style lang="scss"  scoped>
   .contain {
      max-width: 806px;
      margin: 0 auto;
   .title {
      margin-top: 80px;
      margin-bottom: 50px;
      font-weight: bold;;
      font-size: 55px !important;
      font-weight: bold;
      line-height:60px;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
   }
   .prag {
   .items {
   .mb-7 {
      font-weight: bold;;
      font-size: 30px;
      font-weight: bold !important;
      font-style: italic;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
   }
   .mb-12 {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
   }
   }
   }
   }
</style>
